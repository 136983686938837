<!--
 * @Author: hanson 1130845163@qq.com
 * @Date: 2022-07-28 15:20:26
 * @LastEditors: hanson 1130845163@qq.com
 * @LastEditTime: 2022-07-28 15:27:30
 * @FilePath: \2002181_OTLRWS_FRONTED_refactoring\src\views\common\404.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="Not-Found">抱歉! 您没有权限查看该项目</div>
</template>

<script>
export default {

}
</script>

<style>
    .Not-Found {
        margin-top: .2604rem;
        color: white;
        font-size: .1953rem;
        text-align: center;
    }
</style>